import TomSelect from "tom-select";

let placeSelect = null;
let placeSelectTom = null;
let expertiseSelect = null;
let expertiseSelectTom = null;

let querySearch = null;

function initFilters(teamListSearch) {
    if (document.querySelector("#team-place-filter") != null) {
        placeSelect = document.querySelector("#team-place-filter");
        placeSelectTom = new TomSelect(placeSelect, {
            onChange: () => {
                console.log('Place changed');
                changeFilters();
            }
        });
    }

    if (document.querySelector("#team-expertise-filter") != null) {
        expertiseSelect = document.querySelector("#team-expertise-filter");
        expertiseSelectTom = new TomSelect(expertiseSelect, {
            onChange: () => {
                console.log('Expertise changed');
                changeFilters();
            }
        });
    }

    if (document.querySelector("#team-query-search") != null) {
        querySearch = document.querySelector("#team-query-search");
        let queryString = "";
        let oldQueryString = "";

        querySearch.addEventListener('keyup', () => {
            queryString = querySearch.value;

            //Delay the search
            setTimeout(function () {
                if (queryString === querySearch.value && queryString !== oldQueryString) {
                    changeFilters();
                    oldQueryString = queryString;
                }
            }, 200);
        });
    }

    //Filter form on submit
    teamListSearch.addEventListener('submit', (e) => {
        if (!teamListSearch.getAttribute('action')) {
            e.preventDefault();
        }
        changeFilters();
    });

    changeFilters(false);
}

//Loads Filters set in the URL
function getFiltersFromUrl() {
    const url = new URL(window.location.href);
    const place = url.searchParams.get('place');
    const expertise = url.searchParams.get('expertise');
    const query = url.searchParams.get('query');

    if (document.querySelector("#team-place-filter")) {
        if (place != null) {
            //placeSelect.value = place;
            // placeSelectTom.setValue(place);
            // placeSelectTom.setTextboxValue(place);
            document.querySelector("#team-place-filter").querySelector('[value="' + place + '"]').selected = true;
            console.log("Found Place in URL: " + place);
        }
    }
    if (expertise != null) {
        //expertiseSelect.value = expertise;
        // expertiseSelectTom.setValue(expertise);
        // expertiseSelectTom.setTextboxValue(expertise);
        document.querySelector("#team-expertise-filter").querySelector('[value="' + expertise + '"]').selected = true;
        console.log("Found Expertise in URL: " + expertise);
    }
    if (query != null) {
        document.querySelector("#team-query-search").value = query;
        console.log("Found Query in URL: " + query);
    }
}

function changeFilters(copyToUrl = true) {
    let placeValue = "all";
    if (placeSelect != null) {
        placeValue = placeSelect.value;
    }
    if (copyToUrl) {
        let url = new URL(window.location.href);
        if (placeSelect != null) {
            url.searchParams.set('place', placeSelect.value);
        }
        url.searchParams.set('expertise', expertiseSelect.value);
        url.searchParams.set('query', querySearch.value);
        window.history.pushState({}, '', url);
    }
    filterLawyers(placeValue, expertiseSelect.value, querySearch.value);
}


//Filter Lawyers for filters
function filterLawyers(placeFilter, expertiseFilter, queryFilter) {
    const items = document.querySelectorAll('[data-behaviour="teamlist-lawyer"]');
    items.forEach(item => {
        item.classList.remove('teamfilter--item--hidden');
        const place = item.getAttribute('data-place').toLowerCase();
        const expertise = item.getAttribute('data-expertises').toLowerCase();
        const query = item.getAttribute('data-title').toLowerCase();

        if (placeFilter.toLowerCase() !== place && placeFilter !== 'all') {
            item.classList.add('teamfilter--item--hidden');
        }

        //get all expertises by exploding with comma
        const expertises = expertise.split(', ');
        const willShow = false;

        //Check if the expertise filter is in the expertises
        if (expertiseFilter.toLowerCase() === 'all' || expertises.includes(expertiseFilter.toLowerCase())) {
        } else {
            item.classList.add('teamfilter--item--hidden');
        }

        if (queryFilter.toLowerCase() !== '' && !query.includes(queryFilter.toLowerCase())) {
            item.classList.add('teamfilter--item--hidden');
        }
    });
}

document.addEventListener("DOMContentLoaded", function (event) {
    const teamListSearch = document.querySelector('[data-behaviour="teamlist-search"]');

    if (teamListSearch != null) {
        //Get Filters if set from url
        getFiltersFromUrl();
        initFilters(teamListSearch);
    }
});
