import Swiper, {Autoplay, EffectCoverflow, EffectFade, Navigation, Pagination} from 'swiper';
import GLightbox from "glightbox";
import {animate, glide, spring, stagger, timeline} from "motion";

import './TeamPage.js';

document.addEventListener("DOMContentLoaded", function (event) {

    // INIT MENUBUTTON
    const menu_button = document.querySelector('[data-behaviour="toggle-menu"]');
    menu_button.addEventListener('click', () => {
        document.body.classList.toggle('body--show');
    });

    const overlay = document.querySelector('.nav_menu_wrap');
    overlay.addEventListener('click', (e) => {
        if (e.target === overlay) {
            document.body.classList.remove('body--show');
        }
    });

    const hero = document.querySelector('[data-component="hero"]');
    if (hero) {
        const textLines = hero.querySelectorAll('.section_text li');
        const sequence = [];
        textLines.forEach((line, index) => {
            const line1 = line.classList.contains('textline1');
            sequence.push([
                line,
                {
                    opacity: [0, 1],
                    transform: ['translate3d(100%, 0, 0)', 'translate3d(25%, 0, 0)'],
                    filter: ['blur(4px)', 'blur(0px)']
                },
                {duration: 1, delay: line1 ? .5 : 0, easing: glide({velocity: -1000, min: -60})},
            ])
            sequence.push([
                line,
                {opacity: 1, transform: 'translate3d(0, 0, 0)'},
                {duration: 2, easing: 'linear'},
            ])
            sequence.push([
                line,
                {opacity: [1, 0], transform: ['translate3d(0, 0, 0)', 'translate3d(-100%, 0, 0)']},
                {duration: 1, easing: glide({velocity: -1000, min: -60})},
            ])
            // animate(line, {
            //     transform: 'translate3d(0, 0, 0)',
            // }, {
            //     duration: 4,
            //     delay: 4 * index,
            //     easing: 'ease-in-out',
            // })
            // animate(line, {
            //     transform: 'translate3d(-100%, 0, 0)',
            // }, {
            //     duration: 4,
            //     delay: 4 * (index + 1),
            //     easing: 'ease-in-out',
            // })
        });
        timeline(sequence, {
            repeat: Infinity,
        })
    }

    // INIT LIGHTBOX
    const lightbox = GLightbox({
        selector: '[data-gallery="gallery"]',
        touchNavigation: true,
        loop: true,
    });

    // INIT SWIPER
    const sliders = document.querySelectorAll('.swiper');
    sliders.forEach(function (slider) {
        const autoSwiper = slider.classList.contains('swiper--auto');
        const showmultiple = slider.classList.contains('swiper--multiple');
        const noloop = slider.classList.contains('swiper--noloop');

        const swiper = new Swiper(slider, {
            // configure Swiper to use modules
            modules: [Pagination, Navigation, Autoplay, EffectFade],
            effect: 'slide',
            fadeEffect: {
                crossFade: true
            },
            direction: 'horizontal',
            loop: !noloop,

            autoplay: autoSwiper ? {
                delay: 5000,
            } : false,

            slidesPerView: showmultiple ? 2 : 1,
            spaceBetween: showmultiple ? 0 : 10,
            centeredSlides: showmultiple,

            // Navigation arrows
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },

            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true,
            },
        });
    });

    // FIXED HEADER
    window.addEventListener('scroll', () => {
        if (document.documentElement.scrollTop > 30 || document.body.scrollTop > 30) {
            document.body.classList.add('menu--fixed');
        } else {
            document.body.classList.remove('menu--fixed');
        }
    });

    // Expandable Menu
    const nav_link_togglers = document.querySelectorAll('[data-behaviour="nav_link_expand"]');
    nav_link_togglers.forEach((toggler) => {
        toggler.addEventListener('click', () => {
            toggler.parentElement.classList.toggle('nav--expanded');
        });
    });

    //Select as Redirect Menu
    const selectMenus = document.querySelectorAll('[data-behaviour="select-redirect"]');
    if (selectMenus) {
        selectMenus.forEach((selectMenu) => {
            selectMenu.addEventListener('change', () => {
                window.location.href = selectMenu.value;
            });
        });
    }

    //To Top Button
    const upbutton = document.querySelector('[data-behaviour="upbutton"]');
    if (upbutton) {
        upbutton.addEventListener('click', () => {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        });
    }

    // Accordion
    const accordions = document.querySelectorAll('[data-behaviour="accordion"]');
    accordions.forEach((accordion) => {
        accordion.addEventListener('change', () => {
            // Close other accordions
            accordions.forEach((otherAccordion) => {
                if (otherAccordion !== accordion) {
                    otherAccordion.checked = false;
                }
            });
        });
    });


    const deskmap = document.querySelector('[data-behaviour="map-select"]');
    const maptexts = document.querySelectorAll('[data-behaviour="map-text"]');
    const newmaptexts = document.querySelectorAll('[data-behaviour="map-inlinetext"]');
    if (deskmap && maptexts) {
        const targets = deskmap.querySelectorAll('[data-behaviour="map-target"]');

        maptexts.forEach(maptext => {
            maptext.addEventListener('mouseenter', () => {
                maptext.classList.add('maptext--active');
                targets.forEach(target => {
                    if (target.getAttribute('data-place') === maptext.getAttribute('data-place')) {
                        target.classList.add('maptarget--active');
                    } else {
                        target.classList.remove('maptarget--active');
                    }
                });
            });

            maptext.addEventListener('mouseleave', () => {
                maptext.classList.remove('maptext--active');
                targets.forEach(target => {
                    target.classList.remove('maptarget--active');
                });
            });

            targets.forEach(target => {
                if (target.getAttribute('data-place') === maptext.getAttribute('data-place')) {
                    target.classList.add('map_country--active');
                }
            });
        });

        targets.forEach(target => {


            if (target.classList.contains('map_country--active')) {

                const textNS = target.querySelector('.map_country_text');
                maptexts.forEach(maptext => {
                    if (maptext.getAttribute('data-place') === textNS.getAttribute('data-place')) {
                        let textContent = maptext.textContent;

                        const textParts = textContent.split('&');
                        if (textParts.length > 1) {
                            textNS.textContent = textParts[0] + '&';
                            const textNS2 = textNS.nextElementSibling;
                            // console.log(textNS2)
                            textNS2.textContent = textParts[1];
                        } else {
                            textNS.textContent = textContent;
                        }
                    }
                });


                //CHECK FOR INTERACTION
                target.addEventListener('mouseenter', () => {
                    target.classList.add('maptarget--active');
                    maptexts.forEach(maptext => {
                        if (maptext.getAttribute('data-place') === target.getAttribute('data-place')) {
                            maptext.classList.add('maptext--active');
                        } else {
                            maptext.classList.remove('maptext--active');
                        }
                    });
                    newmaptexts.forEach(maptext => {
                        if (maptext.getAttribute('data-place') === target.getAttribute('data-place')) {
                            maptext.classList.add('maptext--active');
                        } else {
                            maptext.classList.remove('maptext--active');
                        }
                    });
                });
                target.addEventListener('mouseleave', () => {
                    target.classList.remove('maptarget--active');
                    maptexts.forEach(maptext => {
                        maptext.classList.remove('maptext--active');
                    });
                    newmaptexts.forEach(maptext => {
                        maptext.classList.remove('maptext--active');
                    });
                });
                target.addEventListener('click', () => {
                    maptexts.forEach(maptext => {
                        if (maptext.getAttribute('data-place') === target.getAttribute('data-place')) {
                            maptext.click();
                        }
                    });
                    newmaptexts.forEach(maptext => {
                        if (maptext.getAttribute('data-place') === target.getAttribute('data-place')) {
                            maptext.click();
                        }
                    });
                });
            }
        });
    }
});
